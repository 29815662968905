




































































import { Component, Vue } from "vue-property-decorator";
import { UserAddress } from "@/types/user/address";
import { Article } from "@/types/shop/article";
import CartModule, { Cart, CartCommissionItem } from "@/store/modules/cart";
import AlertModule, { AlertType } from "@/store/modules/alert";
import AuthModule from "@/store/modules/auth";
import axios, { APIResponse } from "@/plugins/axios";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";
import CartCommissionList from "@/components/shop/cart/commission/CartCommissionList.vue";
import CartCommissionItemList from "@/components/shop/cart/commission/item/CartCommissionItemList.vue";

/**
 * UserShoppingCart view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        CartCommissionList,
        CartCommissionItemList,
        ArticlePriceLabel,
    },
})
export default class UserShoppingCart extends Vue {
    private cart: Cart<Article> = {
        commissions: [],
    };

    private orderAddressId = this.userAddresses[0]?.id || -1;

    private showMergedCommissionItems = false;

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        this.fetchCart();
    }

    /**
     * @returns merged cart commission items
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get mergedCartCommissionItems(): CartCommissionItem<Article>[] {
        const commissionItems: { [key: number]: CartCommissionItem<Article> } =
            {};

        this.cart.commissions.forEach((c) => {
            c.items.forEach((ci) => {
                if (!commissionItems[ci.article.id]) {
                    commissionItems[ci.article.id] = {
                        article: {
                            ...ci.article,
                        },
                        quantity: ci.quantity,
                    };
                } else {
                    commissionItems[ci.article.id].quantity += ci.quantity;
                }
            });
        });

        return Object.values(commissionItems);
    }

    /**
     * Remove commission from cart
     *
     * @param commissionId commission id
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private removeCommission(commissionId: number) {
        CartModule.removeCommission(commissionId)
            .then(() => this.fetchCart())
            .catch((err) => console.error(err));
    }

    /**
     * Remove article from cart
     *
     * @param commissionId commission id
     * @param articleId article id
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private removeArticle(commissionId: number, articleId: number) {
        CartModule.removeArticle({ commissionId, articleId })
            .then(() => this.fetchCart())
            .catch((err) => console.error(err));
    }

    /**
     * Changes quantity of given commission item
     *
     * @param commission commission
     * @param commissionItem commission item
     * @param newQuantity new quantity
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async updateCommissionItemQuantity(
        commissionId: number,
        articleId: number,
        quantity: number
    ) {
        try {
            await CartModule.updateArticleQuantity({
                commissionId,
                articleId,
                quantity,
            });

            this.fetchCart();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Order cart
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async orderCart() {
        try {
            const response = (
                await axios.post<APIResponse>("/shoppingcart/checkout", {
                    orderAddressId: this.orderAddressId,
                })
            ).data;
            if (response.status === "error") {
                throw new Error(response.message || "unknownError");
            }

            this.$router.push({ name: "shopOrders" });
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches cart from CartModule and saves result in cart variable
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchCart() {
        try {
            // TODO remove ts-ignore comment after configurator generics are fixed
            // @ts-ignore
            this.cart = await CartModule.getCart();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * @returns addresses from logged in user
     */
    private get userAddresses(): UserAddress[] {
        if (!AuthModule.user) return [];

        // TODO remove ts-ignore when vuex generics fixed
        // @ts-ignore
        return AuthModule.user.addresses;
    }
}
